<template>
  <el-form :model="additionalcontractForm" :rules="rules" ref="form" label-width="100px" class="additionalcontractForm" size="small">
    <el-form-item label="追加时间" prop="additional_contract_date">
      <el-date-picker
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
        v-model="additionalcontractForm.additional_contract_date"
        :picker-options="pickerOptions"
        style="width: 100%;"
      >
      </el-date-picker>
    </el-form-item>

    <el-form-item label="追加金额" prop="additional_contract_amount">
      <el-input type="number" step="0.01" placeholder="0要写成0.00" v-model="additionalcontractForm.additional_contract_amount"></el-input>
    </el-form-item>

    <el-form-item label="追加材料" prop="additional_contract_material">
      <el-input type="textarea" :rows="3" v-model="additionalcontractForm.additional_contract_material"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    additionalcontractForm: Object
  },
  data() {
    return {
      isValidationOk: false,

      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        additional_contract_date: [{ required: true, message: '请选择合同外追加时间', trigger: 'blur' }],
        additional_contract_amount: [{ required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/ }]
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.isValidationOk = true
        } else {
          console.log('Validation error!')
          this.isValidationOk = false
          return false
        }
      })
    },
    resetForm() {
      this.$refs['from'].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped></style>
